<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
import Tabber from "@/components/tabber.vue";
export default {
  data() {
    return {};
  },
  created() {
    // 在页面加载时读取sessionStorage
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }
    // 在页面刷新时将store保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
    // document.title = "传奇手游大全 - 2022最新传奇手游排行 - 传奇手游发布网 - 武阔游戏"
  },
  mounted() {},
  components: {
    Tabber,
  },
  methods: {},
};
</script>
<style lang="less">
body {
  margin: 0;
}
html,
body,
ul,
li {
  list-style: none;
}
#app {
  width: 750px;
  margin: auto;
}
</style>
