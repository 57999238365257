<template>
  <div class="tail">
    <div class="box_tail">
      <p>抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防上当受骗</p>
      <p>适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</p>
      <p><a href="https://beian.miit.gov.cn/">闽ICP备2022005801号-2 </a></p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.tail {
  width: 100%;
  height: auto;
  margin-bottom: 60px;
  .box_tail {
    width: 680px;
    height: auto;
    margin: auto;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #afafaf;
    
    p {
      letter-spacing: 1px;
      font-size: 20px;
      text-align: center;
      a {
        font-size: 20px;
        text-decoration: none;
        color: inherit;
      }
    }
  }
}
</style>