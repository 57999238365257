<template>
  <div class="nav">
    <ul>
      <li
        v-for="(item, index) in from"
        :key="item.id"
        :class="[currentTabIndex == index ? 'active' : '']"
        @click="getCurr(item.id, index)"
        :data-id="item.id"
      >
        <a>
          <span>{{ item.name }}</span>
        </a>
      </li>
    </ul>
    <!-- <div class="nav_hamburger" @click="getTop()"></div>
    <div class="nav_popup" v-if="show"></div> -->
    <!-- <van-dropdown-menu class="nav_hamburger">
      <van-dropdown-item
        v-model="value1"
        :options="option1"
        @change="getTop(value1)"
      />
    </van-dropdown-menu> -->
    <van-popover
      v-model="showPopover"
      trigger="click"
      :actions="option1"
      @select="getTop"
      class="vat_box"
      placement="top-end"
    >
      <template #reference>
        <div class="nav_hamburger"></div>
      </template>
    </van-popover>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopover: false,
      from: [
        {
          id: 0,
          name: "首页",
        },
        {
          id: 1,
          name: "传奇版本",
        },
        {
          id: 2,
          name: "手游排行榜",
        },
      ],
      option1: [
        { text: "首页", value: 0 },
        { text: "传奇版本", value: 1 },
        { text: "手游排行榜", value: 2 },
      ],
      currentTabIndex: this.$store.state.currentTabIndex,
    };
  },
  created() {},
  mounted() {},
  methods: {
    getCurr(id) {
      this.$store.state.currentTabIndex = id;
      if (id == 0) {
        this.$router.push("/");
      } else if (id == 1) {
        this.$router.push("/banben");
      } else {
        // this.$toast("敬请期待~");
        this.$router.push("/ranking");
      }
    },
    // 点击头部
    getTop(action) {
      let id = action.value;
      console.log(id);
      if (id == 0) {
        this.$router.push("/");
        this.$store.state.currentTabIndex = 0;
      } else if (id == 1) {
        this.$router.push("/banben");
        this.$store.state.currentTabIndex = 1;
      } else {
        this.$router.push("/ranking");
        this.$store.state.currentTabIndex = 2;
      }
    },
  },
};
</script>
<style>
.vat_box {
  position: absolute;
  right: 20px;
  top: 27px;
  width: 42px;
  height: 38px;
  margin: 0px;
  z-index: 2008;
}
.van-popover__content{
  margin-right: 0px;
}
.van-dropdown-menu__title {
  display: none !important;
}
.van-dropdown-item__content {
  padding-top: 20px;
}
</style>
<style lang="less" scoped>
.nav_popup {
  height: auto;
  width: 750px;
  transition-duration: 0.2s;
  z-index: 2002;
}
// 头部
.nav {
  width: 750px;
  height: 92px;
  padding: 10px 20px;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.07);
  ul {
    margin: 0;
    margin-top: 0px;
    height: 60px;
    padding: 0;
    display: flex;
    align-items: flex-end;
    .active {
      font-size: 34px !important;
      font-family: Hiragino Sans GB;
      font-weight: bold;
      color: #272727;
    }
    .active a span {
      position: relative;
      z-index: 3;
    }
    .active::before {
      position: absolute;
      content: "";
      left: 5%;
      bottom: 0;
      width: 90%;
      height: 13px;
      display: block;
      background: linear-gradient(0deg, #fe2727, #ff5959);
      border-radius: 6px;
      z-index: 2;
    }
    li {
      float: left;
      font-size: 30px;
      font-family: Hiragino Sans GB;
      font-weight: 400;
      color: #656565;
      margin-right: 25px;
      position: relative;
      padding: 4px 6px;
      a {
        text-decoration: none;
        color: inherit;
        position: relative;
        display: inline-block;
      }
    }
  }
  .nav_hamburger {
    // position: absolute;
    // right: 20px;
    // top: 27px;
    width: 42px;
    height: 38px;
    z-index: 9998;
    background: url(../../assets/icon/nav.png);
    background-size: 100% 100%;
  }
}
</style>