<template>
  <div>
    <Nav></Nav>
    <div class="box">
      <div class="box_top">
        <ul class="content">
          <li v-for="item in game" :key="item.id">
            <div class="icon_light" @click="getGameTo(item.id)">
              <img :src="item.avatar" alt="" />
            </div>
            <p @click="getGameTo(item.id)">{{ item.name }}</p>
            <span>评分：{{ item.score }}</span>
            <a @click="getGameTo(item.id)"></a>
          </li>
        </ul>
      </div>
      <ul class="other_rank">
        <li v-for="item in rank" :key="item.id">
          <div class="icon_light" @click="getGameTo(item.id)">
            <img :src="item.avatar" alt="" />
            <span></span>
          </div>
          <div class="message">
            <div>
              <h2 @click="getGameTo(item.id)">{{ item.name }}</h2>
              <div class="type">{{ item.type }}</div>
              <div style="clear: both"></div>
            </div>
            <p class="ells">
              <b class="color_red">{{ item.types }}</b>
            </p>
            <span class="ells" @click="getLike()"
              >玩家点赞数{{ item.like }} <i class="btn_zan">点个赞</i></span
            >
          </div>
          <a @click="getGameTo(item.id)" class="download_btn"></a>
        </li>
      </ul>
    </div>
    <Tail></Tail>
  </div>
</template>

<script>
import Nav from "@/views/index/nav.vue";
import Tail from "@/views/index/tail";
import { GetGameRanking } from "@/request/api.js";
export default {
  data() {
    return {
      game: [],
      rank: [],
    };
  },
  created() {
    this.$store.state.currentTabIndex = 2;
    GetGameRanking().then((res) => {
      console.log(res);
      let add = res.data;
      if (add.msg == "请求成功") {
        this.rank = add.data;
        let arr = add.data.slice(0, 3);
        [arr[0], arr[1]] = [arr[1], arr[0]];
        this.game = arr;
      }
    });
  },
  mounted() {},
  components: {
    Tail,
    Nav,
  },
  methods: {
    // 点击跳转
    getGameTo(id) {
      this.$router.push({
        path: "/game",
        query: { id: id },
      });
    },
    // 点赞
    getLike() {
      this.$toast.success("请体验游戏完后，再来点赞支持噢！");
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  width: 750px;
  margin: 0px auto 60px auto;
  height: auto;
  .box_top {
    width: 100%;
    height: 540px;
    position: relative;
    background: url(../../assets/images/head2.png);
    background-size: 100% 100%;
    .content {
      width: 710px;
      height: 390px;
      position: absolute;
      left: 20px;
      bottom: 22px;
      li {
        float: left;
        height: 390px;
      }
      li:nth-of-type(1) {
        width: 220px;
        padding-top: 68px;
        box-sizing: border-box;
        .icon_light {
          width: 160px;
          height: 160px;
          background: url(../../assets/images/02.gif) center center/cover
            no-repeat;
          margin-left: 40px;
          padding: 25.2px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          img {
            display: block;
            width: 106px;
            height: 106px;
            border-radius: 24px;
          }
        }
        p {
          width: 171px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #692606;
          font-size: 28px;
          color: #fff;
          margin: 13px 0 0 30px;
          border-radius: 2px;
          overflow: hidden;
        }
        span {
          display: block;
          width: 171px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 22px;
          color: #f7f1f1;
          margin: 10px 0 0 30px;
          overflow: hidden;
        }
        a {
          display: block;
          width: 130px;
          height: 48px;
          margin: 13px 0 0 55px;
          background: url(../../assets/images/xiazai_bg.gif) center center/cover
            no-repeat;
        }
      }
      li:nth-of-type(2) {
        width: 258.5px;
        box-sizing: border-box;
        .icon_light {
          width: 200px;
          height: 200px;
          background: url(../../assets/images/01.gif) center center/cover
            no-repeat;
          margin-left: 29px;
          padding-top: 26px;
          padding-left: 30px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          img {
            display: block;
            width: 135px;
            height: 135px;
            border-radius: 24px;
          }
        }
        p {
          width: 171px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #692606;
          font-size: 28px;
          color: #fff;
          margin: 41.3px 0 0 43px;
          border-radius: 2px;
          overflow: hidden;
        }
        span {
          display: block;
          width: 171px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 22px;
          color: #f7f1f1;
          margin: 10px auto 2px;
          overflow: hidden;
        }
        a {
          display: block;
          width: 130px;
          height: 48px;
          margin: 13px 0 0 70px;
          background: url(../../assets/images/xiazai_bg.gif) center center/cover
            no-repeat;
        }
      }
      li:nth-of-type(3) {
        width: 230px;
        padding-top: 91.6px;
        box-sizing: border-box;
        .icon_light {
          width: 142px;
          height: 142px;
          background: url(../../assets/images/03.gif) center center/cover
            no-repeat;
          margin-left: 31.5px;
          padding-top: 22.5px;
          padding-left: 26px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          img {
            display: block;
            width: 99.8px;
            height: 99.8px;
            border-radius: 24px;
          }
        }
        p {
          width: 171px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          background: #692606;
          font-size: 28px;
          color: #fff;
          margin: 13px 0 0 30px;
          border-radius: 2px;
          overflow: hidden;
        }
        span {
          display: block;
          width: 171px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          font-size: 22px;
          color: #f7f1f1;
          margin: 10px auto 2px;
          overflow: hidden;
        }
        a {
          display: block;
          width: 130px;
          height: 48px;
          margin: 15px 0 0 60px;
          background: url(../../assets/images/xiazai_bg.gif) center center/cover
            no-repeat;
        }
      }
    }
  }
  //
  .other_rank {
    width: 100%;
    padding: 18px 0;
    background: #33333e;
    li {
      width: 710px;
      height: 160px;
      margin: 18px auto 0;
      position: relative;
      background: #1f1f27;
      .icon_light {
        width: 193px;
        height: 182px;
        position: absolute;
        top: -2px;
        left: -5px;
        padding-top: 27px;
        padding-left: 37.8px;
        box-sizing: border-box;
        img {
          display: block;
          width: 112px;
          height: 112px;
          border-radius: 24px;
        }
      }
      .message {
        width: 390px;
        position: absolute;
        top: 0;
        left: 182px;
        padding-top: 25px;
        h2 {
          margin: 0;
          padding: 0;
          border: 0;
          outline: none;
          float: left;
          font-size: 32px;
          color: #fff;
          margin-bottom: 2px;
        }
        .type {
          float: left;
          padding: 2px 6px;
          font-size: 18px;
          color: #fff;
          background: #7f1807;
          display: inline-block;
          border-radius: 2px;
          margin-top: -4px;
          margin-left: 12px;
        }
        p {
          margin: 0;
          padding: 0;
          border: 0;
          outline: none;
          font-size: 26px;
          color: #7f7d7d;
          margin-bottom: 4px;
          b {
            margin-right: 15px;
            font-weight: normal;
            font-size: 25px;
            border-radius: 5px;
          }
        }
        span {
          display: inline-block;
          font-size: 23px;
          color: #7f7d7d;
        }
        .ells {
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          overflow: hidden;
        }
        .color_red {
          color: #e1424d;
        }
        .color_orange {
          color: #ef7d08;
        }
        .color_blue {
          color: #3a6dee;
        }
        .color_green {
          color: #2aa11c;
        }
        .btn_zan {
          display: inline-block;
          padding: 0 7px 0 23px;
          height: 30px;
          margin-left: 10px;
          box-sizing: border-box;
          border-radius: 5px;
          font-size: 24px;
          line-height: 30px;
          border: 1px solid #626273;
          background: url(../../assets/images/zan.png) left center no-repeat;
          background-size: 22px;
          font-style: normal;
          color: #aeaebc;
          cursor: pointer;
        }
      }
      a {
        display: block;
        width: 140px;
        height: 56px;
        position: absolute;
        top: 55px;
        right: 10px;
        background: url(../../assets/images/xiazai_game.gif) center center/cover
          no-repeat;
      }
    }
    li:nth-of-type(1) {
      margin-top: 0;
      background: url(../../assets/images/li1.gif) top left/cover no-repeat;
      .icon_light {
        width: 185px;
        height: 178px;
        position: absolute;
        top: -8px;
        left: 0px;
        padding-top: 30px;
        padding-left: 35px;
        box-sizing: border-box;
        img {
          display: block;
          width: 112px;
          height: 112px;
          border-radius: 24px;
        }
        span {
          display: block;
          width: 172px;
          height: 38px;
          background: url(../../assets/images/no1.png) center center/cover
            no-repeat;
          position: absolute;
          bottom: 29px;
          left: 5px;
        }
      }
      .message {
        width: 390px;
        position: absolute;
        top: 0;
        left: 182px;
        padding-top: 27px;
        .type {
          background: #dd33dd !important;
        }
        .color_red {
          color: #e1424d;
        }
      }
    }
    li:nth-of-type(2) {
      margin-top: 18px;
      background: url(../../assets/images/li2.gif) top left/cover no-repeat;
      .type {
        background: #ff7700 !important;
      }
      .icon_light {
        span {
          display: block;
          width: 170px;
          height: 38px;
          background: url(../../assets/images/no2.png) center center/cover
            no-repeat;
          position: absolute;
          bottom: 38.3px;
          left: 5px;
        }
      }
    }
  }
}
</style>