import Vue from 'vue'
import { Toast } from 'vant';
import { Swipe, SwipeItem } from 'vant';

import { DropdownMenu, DropdownItem } from 'vant';
import { Popup } from 'vant';
import { Popover } from 'vant';

Vue.use(Popover);
Vue.use(Popup);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Toast);