import Vue from 'vue'
import VueRouter from 'vue-router'
import Game from '@/views/index/game_details.vue'
import Tab from '@/components/tabber.vue'
import Version from '@/views/index/game_version.vue'
import Ranking from '@/views/index/ranking.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Tab',
    component: Tab,
    meta: {
      title: "传奇手游大全 - 2022最新传奇手游排行 - 传奇手游发布网 - 武阔游戏",
      content: {
        keywords: '传奇手游,传奇手游大全,传奇手游排行',
        description: '传奇手游大全，2022最新传奇手游排行，传奇手游发布网，武阔游戏。'
      }
    }
  },
  {
    path: '/game',
    name: 'Game',
    component: Game,
    meta: {
      title: "武阔天下游戏 - 传奇手游发布网 - 武阔游戏",
      content: {
        keywords: '武阔天下游戏',
        description: '武阔天下游戏，传奇手游发布网，武阔游戏。'
      }
    }
  },
  {
    path: '/banben',
    name: 'Version',
    component: Version,
    meta: {
      title: "传奇手游版本大全 - 传奇手游发布网 - 武阔游戏",
      content: {
        keywords: '传奇手游版本大全,传奇手游版本',
        description: '传奇手游版本大全，传奇手游发布网，武阔游戏。'
      }
    }
  },
  {
    path: '/ranking',
    name: 'Ranking',
    component: Ranking,
    meta: {
      title: "传奇手游排行榜 - 最好玩的传奇手游排行榜 - 传奇手游发布网 - 武阔游戏",
      content: {
        keywords: '传奇手游排行榜,最好玩的传奇手游排行榜',
        description: '传奇手游排行榜，最好玩的传奇手游排行榜，传奇手游发布网，武阔游戏。'
      }
    }
  }
]



const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // 统计
  if (_hmt) {
    if (to.path) {
      _hmt.push(['_trackPageview', '/#' + to.fullPath]);
    }
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default router