import axios from "axios"
import qs from 'qs'
//创建一个单例（实例）
const instance = axios.create({
    baseURL: "https://api.wu7.com/index.php",
    timeout: 5000,
    // changeOrigin: true,
})

//请求拦截
//instance.interceptors.request.use(callback,callback)
instance.interceptors.request.use(config => {
    // console.log("每一次发起请求前，都会先执行这里的代码");
    // console.log(config); //config本次请求的配置信息
    config.data = qs.stringify(config.data) // 转为formdata数据格式
    return config
}, err => {
    return Promise.reject(err)
})

//响应拦截
//instance.interceptors.response.use(callback,callback)
instance.interceptors.response.use(res => {
    // console.log("每一次接收到响应，都会先执行这里的代码，再去执行成功的那个回调函数then");
    return res
}, err => {
    return Promise.reject(err)
})


//按需导出
export default instance