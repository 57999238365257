import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "lib-flexible/flexible"
import '@/components/vant'
import 'vant/lib/icon/local.css'

Vue.config.productionTip = false

var _hmt = _hmt || [];
window._hmt = _hmt; // 修改为window 全局变量
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?d1618802f87c3f8235987c7c368b2e76";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')