import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tabber: 0,
    currentTabIndex:0,
    indexes: null,
    game: [],
  },
  mutations: {

  },
  actions: {},
  modules: {}
})