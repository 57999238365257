<template>
  <div class="box">
    <div>
      <div class="box_top">
        <img :src="list.image" alt="" />
        <div class="box_page" @click="getPage()"></div>
        <van-popover
          v-model="showPopover"
          trigger="click"
          :actions="actions"
          @select="onSelect"
          placement="top-end"
        >
          <template #reference>
            <div class="box_submenu"></div>
          </template>
        </van-popover>
      </div>
      <div class="main">
        <div class="main_top">
          <div class="main_lf">
            <img :src="list.avatar" alt="" />
          </div>
          <div class="main_tab">
            <div class="main_clumn">
              <div class="main_row">
                <h1>{{ list.name }}</h1>
                <div class="lab">{{ list.type }}</div>
              </div>
              <div class="main_tt">
                <div
                  class="main_laber"
                  v-for="(item, index) in list.versions"
                  :key="index"
                >
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
          <div class="main_score">
            <div class="score_ver"></div>
            <div class="score_box">
              <div class="sco_num">{{ list.score }}</div>
              <div class="sco_title">综合评分</div>
            </div>
          </div>
        </div>
        <!-- 专属 -->
        <div class="user_details">
          <div class="gift">
            <div class="gift_font">
              <div class="gift_name">专属礼包码</div>
              <div class="gift_num">{{ list.gift }}</div>
            </div>
            <div class="gift_icon" @click="getGive()">
              <img :src="imageUrl" alt="" />
            </div>
          </div>
          <div class="gift">
            <div class="gift_font">
              <div class="gift_name">客服QQ</div>
              <div class="gift_num">{{ list.qq }}</div>
            </div>
            <div class="gift_tt">{{ list.like }} 点赞</div>
          </div>
        </div>
        <!-- 下载 -->
        <div class="game_download">
          <a :href="list.pc_url">
            <button class="load_lf">电脑端安装</button>
          </a>
          <a :href="list.mobile_url">
            <button class="load_rg">
              手机端安装 （{{ list.mobile_size }}）
            </button>
          </a>
        </div>
        <div class="heixia"></div>
        <!-- 轮播图 -->
        <div class="swiper_box">
          <van-swipe :loop="false" :width="350">
            <van-swipe-item
              v-for="(item, index) in list.images"
              :key="index"
              @click="getImgPreview()"
            >
              <img :src="item" alt="" class="swp_img" :width="340" />
            </van-swipe-item>
          </van-swipe>
        </div>
        <!-- 标题 -->
        <div class="game_wz_title">
          <div class="wz_lf"></div>
          <div class="wz_tt">{{ list.types }}</div>
          <div class="wz_rg"></div>
        </div>
        <div class="wz_content" v-html="list.content"></div>
        <div class="wz_button">—— 已经到底了 ——</div>
      </div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
import { GetGameDetail } from "@/request/api.js";
export default {
  data() {
    return {
      list: [],
      imageUrl: require("../../assets/icon/iconPraise2.png"),
      imgUrl: [],
      showPopover: false,

      actions: [
        { text: "首页", value: 0 },
        { text: "传奇版本", value: 1 },
        { text: "手游排行榜", value: 2 },
      ],
    };
  },
  created() {
    let id = this.$route.query.id;
    GetGameDetail(id).then((res) => {
      let add = res.data;
      if (add.msg == "请求成功") {
        this.list = add.data;
        this.imgUrl = add.data.images;
      }
    });
    window.scrollTo(0, 0);
  },
  mounted() {},
  methods: {
    getPage() {
      this.$store.state.currentTabIndex = 0;
      this.$router.replace("/");
    },
    onSelect(action) {
      let arr = action.value;
      if (arr == 0) {
        this.$router.push("/");
        this.$store.state.currentTabIndex = 0;
      } else if (arr == 1) {
        this.$router.push("/banben");
        this.$store.state.currentTabIndex = 1;
      } else {
        this.$router.push("/ranking");
        this.$store.state.currentTabIndex = 2;
      }
    },
    // 点赞
    getGive() {
      this.imageUrl = require("../../assets/icon/iconPraise1.png");
    },
    getImgPreview() {
      let arr = this.imgUrl;
      ImagePreview({ images: arr });
    },
  },
};
</script>
<style>
.van-popup {
  position: absolute;
  left: 252px;
  top: 88px !important;
  margin: 0px;
  z-index: 2008;
}
.van-popover__arrow {
  top: -7px !important;
  right: 38px !important;
  /* bottom: none; */
  transform: rotate(180deg) !important;
  -webkit-transform: rotate(180deg) !important;
}
.van-popover__content {
  margin-top: 0px;
  margin-right: 20px;
}
</style>
<style lang="less" scoped>
.box {
  width: 750px;
  margin: auto;
  height: auto;
  .box_top {
    position: relative;
    width: 100%;
    height: 387px;
    img {
      width: 100%;
      height: 387px;
    }
    .box_page {
      position: absolute;
      z-index: 2;
      top: 22px;
      left: 24px;
      width: 52px;
      height: 52px;
      background: url(../../assets/icon/home.png);
      background-size: 100% 100%;
    }
    .box_submenu {
      position: absolute;
      z-index: 2;
      top: 22px;
      right: 24px;
      width: 52px;
      height: 52px;
      background: url(../../assets/icon/navigate2.png);
      background-size: 100% 100%;
    }
  }
  .main {
    padding: 38px 30px;
    width: 690px;
    height: auto;
    .main_top {
      width: 100%;
      height: 127px;
      .main_lf {
        float: left;
        width: 127px;
        height: 127px;
        border-radius: 24px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 24px;
        }
      }
      .main_tab {
        float: left;
        margin-left: 20px;
        width: 400px;
        height: 127px;
        .main_clumn {
          display: flex;
          flex-direction: column;
          .main_row {
            h1 {
              float: left;
              padding: 0;
              margin: 0;
              font-size: 36px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #353535;
            }
            .lab {
              float: left;
              margin-left: 20px;
              margin-top: 10px;
              width: 63px;
              height: 32px;
              line-height: 32px;
              text-align: center;
              background: linear-gradient(0deg, #2d7ef8, #17a0fd);
              border-radius: 5px;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
            }
          }
          .main_tt {
            margin-top: 17px;
            .main_laber {
              float: left;
              padding: 4px 8px;
              margin-right: 10px;
              background: rgba(255, 146, 4, 0);
              border: 1px solid rgba(207, 207, 207, 0.88);
              border-radius: 8px;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #aaaaaa;
            }
            .main_laber:last-child {
              margin-right: 0 !important;
            }
          }
        }
      }
      .main_score {
        float: right;
        width: 143px;
        height: 127px;
        .score_ver {
          float: left;
          width: 2px;
          height: 108px;
          background: #efefef;
        }
        .score_box {
          float: right;
          width: 141px;
          height: 127px;
          .sco_num {
            width: 100%;
            text-align: right;
            font-size: 57px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #ff8c05;
            background: linear-gradient(
              0deg,
              #ff5302 0.3662109375%,
              #ff9804 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .sco_title {
            width: 100%;
            text-align: right;
            font-size: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #a2a2a2;
          }
        }
      }
    }
    // 专属
    .user_details {
      width: 100%;
      height: 90px;
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      .gift {
        margin: 10px 0;
        position: relative;
        .gift_font {
          // float: left;
          position: absolute;
          bottom: 0;
          left: 0;
          margin-top: 0px;
          .gift_name {
            float: left;
            font-size: 26px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #bababa;
          }
          .gift_num {
            float: left;
            margin-left: 17px;
            font-size: 26px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #777777;
          }
        }

        .gift_icon {
          float: right;
          width: 47px;
          height: 47px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .gift_tt {
          float: right;
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a2a2a2;
        }
      }
      .gift:nth-of-type(1) {
        margin-top: 0px;
        margin-bottom: 16px;
      }
      .gift:last-child {
        margin: 0;
      }
    }
    // 下载
    .game_download {
      width: 100%;
      height: 75px;
      margin-top: 60px;
      .load_lf {
        float: left;
        padding: 0;
        margin: 0;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ff4a4a;
        width: 179px;
        height: 75px;
        line-height: 75px;
        text-align: center;
        background: rgba(255, 74, 74, 0);
        border: 2px solid #ff4a4a;
        border-radius: 8px;
        margin-right: 16px;
      }
      .load_rg {
        float: right;
        padding: 0;
        margin: 0;
        border: 0;
        width: 494px;
        height: 75px;
        background: #ff4a4a;
        border-radius: 8px;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #ffffff;
      }
    }
    // 虚线
    .heixia {
      margin: 56px 0 66px 0;
      width: 100%;
      height: 1px;
      background: #dedede;
    }
    // 轮播图
    .swiper_box {
      width: 100%;
      height: 380px;
      margin-bottom: 77px;
      .swp_img {
        // width: 370px;
        height: 380px;
        background: #000000;
        border-radius: 12px;
      }
    }
    // 标题
    .game_wz_title {
      margin-bottom: 52px;
      display: flex;
      align-items: center;
      justify-content: center;
      .wz_lf {
        width: 37px;
        height: 37px;
        background: url(../../assets/icon/comma1.png);
        background-size: 100% 100%;
      }
      .wz_tt {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin: 0 18px;
      }
      .wz_rg {
        width: 37px;
        height: 37px;
        background: url(../../assets/icon/comma2.png);
        background-size: 100% 100%;
      }
    }
    .wz_content {
      font-size: 27px;
      letter-spacing: 1px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #696868;
      line-height: 52px;
      margin-bottom: 92px;
    }
    .wz_button {
      width: 100%;
      text-align: center;
      font-size: 27px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #cac9c9;
      line-height: 24px;
      margin-bottom: 87px;
    }
  }
}
</style>