<template>
  <div>
    <Nav></Nav>
    <!-- 首页 -->
    <div class="content">
      <div class="box">
        <div
          class="box_game"
          v-for="item in game"
          :key="item.id"
          @click="getGameTo(item.id)"
        >
          <div class="bg_game">
            <img :src="item.image" :alt="item.name" />
          </div>
          <div class="game_content">
            <div class="game_row">
              <div class="par_img">
                <img :src="item.avatar" alt="" />
              </div>
              <div class="gm_cloumn">
                <div class="gm_rw">
                  <h1>{{ item.name }}</h1>
                  <div class="gm_label">{{ item.type }}</div>
                </div>
                <div class="box_label">
                  <div
                    class="label"
                    v-for="(item, index) in item.versions"
                    :key="index"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>
              <div class="score_num">
                <!-- <div class="sco_vertical"></div> -->
                <div class="score_box flex_column">
                  <div class="score_nums">{{ item.score }}</div>
                  <div class="score_title">{{ item.like }}点赞</div>
                </div>
              </div>
            </div>
            <div class="game_default">
              <div class="game_text">
                {{ item.desc }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Tail></Tail>
  </div>
</template>

<script>
import Nav from "@/views/index/nav.vue";
import Tail from "@/views/index/tail";
import { GetGamelist, GetVersion, GetGameSearch } from "@/request/api.js";
export default {
  data() {
    return {
      from: [
        {
          id: 0,
          name: "首页",
        },
        {
          id: 1,
          name: "传奇版本",
        },
        {
          id: 2,
          name: "手游排行榜",
        },
      ],
      game: [],
      tabber: true,
      gamelist: [],
    };
  },
  created() {
    GetGamelist().then((res) => {
      let add = res.data;
      if (add.msg == "请求成功") {
        this.game = add.data;
      } else {
        console.log("请求列表有问题");
      }
    });
    GetVersion().then((res) => {
      let add = res.data;
      if (add.msg == "请求成功") {
        this.gamelist = add.data;
      } else {
        console.log("请求关键词有问题");
      }
    });
  },
  mounted() {},
  components: {
    Tail,
    Nav,
  },
  methods: {
    // 点击跳转
    getGameTo(id) {
      this.$router.push({
        path: "/game",
        query: { id: id },
      });
    },
    //
    getSearch(id) {
      GetGameSearch(id).then((res) => {
        let add = res.data;
        if (add.data.length == 0) {
          this.$toast("暂未相关游戏~");
        } else {
          this.game = add.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
// 内容
.content {
  width: 750px;
  margin: 0 auto;
  height: auto;
  background: #f5f5f5;
  padding: 20px 0;
  // 传奇版本
  .version {
    width: 690px;
    height: auto;
    padding: 26px 30px 0px 30px;
    .ver_flex {
      display: flex;
      flex-wrap: wrap;
      .ver_box {
        width: 121px;
        height: 60px;
        background: #ffffff;
        border: 1px solid rgba(214, 214, 214, 0.88);
        box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.03);
        border-radius: 30px;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #868686;
        margin-right: 16px;
        margin-bottom: 26px;
        line-height: 60px;
        text-align: center;
      }
      .ver_box:nth-of-type(5n) {
        margin-right: 0;
      }
    }
    // 介绍
    .ver_details {
      width: 688px;
      padding: 0px 0px 30px 0;
      height: auto;
      border: 2px solid #f9bebe;
      box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.03);
      border-radius: 14px;
      .deta_box {
        width: 100%;
        height: 323px;
        .deta_img {
          position: relative;
          width: 688px;
          height: 90px;
          background: url(../assets/images/bg.png);
          background-size: 100% 100%;
          .deta_icon {
            position: absolute;
            top: 34px;
            left: 23px;
            width: 33px;
            height: 33px;
            background: url(../assets/images/circular.png);
            background-size: 100% 100%;
          }
          .deta_title {
            padding: 29px 0 0 73px;
            font-size: 30px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #272727;
          }
        }
        .deta_pad {
          width: 640px;
          margin: 12px auto 0 auto;
          font-size: 27px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #777777;
          // line-height: 24px;
          letter-spacing: 1px;
          line-height: 50px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          text-indent: 2em;
        }
      }
    }
  }
  .box {
    width: 690px;
    height: auto;
    padding: 20px 30px;
    background: #f5f5f5;
    .box_game {
      width: 100%;
      height: auto;
      // box-shadow: 0px 6px 12px 10px rgba(0, 0, 0, 0.03);
      box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.03);
      border-radius: 14px;
      margin-bottom: 30px;
      .bg_game {
        width: 100%;
        height: 387px;
        img {
          width: 100%;
          height: 387px;
          border-top-left-radius: 14px;
          border-top-right-radius: 14px;
        }
      }
      .game_content {
        width: 650px;
        height: 146px;
        padding: 20px;
        background: #ffffff;
        border-bottom-left-radius: 14px;
        border-bottom-right-radius: 14px;
        .game_row {
          width: 100%;
          height: 100px;
          display: flex;
          flex-direction: row;
          .par_img {
            width: 100px;
            height: 100px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 24px;
            }
          }
          .gm_cloumn {
            margin-left: 20px;
            width: 450px;
            display: flex;
            flex-direction: column;
            height: 100px;
            .gm_rw {
              width: auto;
              h1 {
                float: left;
                font-size: 31px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #353535;
                padding: 0;
                margin: 0;
              }
              .gm_label {
                float: left;
                margin-top: 6px;
                width: 63px;
                height: 32px;
                background: linear-gradient(0deg, #2d7ef8, #17a0fd);
                border-radius: 5px;
                line-height: 32px;
                text-align: center;
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                margin-left: 13px;
              }
            }
            .box_label {
              width: auto;
              margin-top: 9px;
              .label {
                float: left;
                padding: 4px 8px;
                background: rgba(255, 146, 4, 0);
                border: 1px solid rgba(207, 207, 207, 0.88);
                border-radius: 8px;
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #aaaaaa;
                margin-right: 10px;
              }
            }
          }
          .score_num {
            float: right;
            width: 120px;
            height: 100px;
            display: flex;
            flex-direction: row;
            .sco_vertical {
              width: 2px;
              height: 74px;
              background: #efefef;
            }
            .score_box {
              width: 118px;
              height: 100px;
              .score_nums {
                width: 100%;
                text-align: right;
                font-size: 47px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ff8c05;
                background: linear-gradient(
                  0deg,
                  #ff5302 0.3662109375%,
                  #ff9804 100%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
              .score_title {
                width: 100%;
                text-align: right;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 20px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #a2a2a2;
              }
            }
          }
        }
        .game_default {
          width: 100%;
          height: 46px;
          // line-height: 46px;
          position: relative;
          bottom: 0;

          .game_text {
            width: 100%;
            position: absolute;
            bottom: 0;
            font-size: 22px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #777777;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
.flex_column {
  display: flex;
  flex-direction: column;
}
</style>