//可以统一管理所有的api接口
import request from './request'

// 版本关键词
export function GetVersion() {
  return request({
    url: '/api/version',
    method: 'GET',
  })
}
// 游戏列表
export function GetGamelist() {
  return request({
    url: '/api/game',
    method: 'GET',
  })
}
// 搜索调用的游戏列表
export function GetGameSearch(id) {
  return request({
    url: '/api/game' + '?version_id=' + id,
    method: 'GET',
  })
}
// 游戏详情
export function GetGameDetail(id) {
  return request({
    url: '/api/game/detail' + '?id=' + id,
    method: 'GET',
  })
}
// 手游排行榜
export function GetGameRanking() {
  return request({
    url: '/api/game/rank',
    method: 'GET',
  })
}